<template>
  <a-form ref="form" :model="search" :style="{ marginBottom: '20px' }" :initialValues="search"
    :onFinish="pageSearchChange" layout="inline">
    <a-form-item label="品牌商" name="keywords">
      <a-input v-model:value="search.keywords" style="width: 250px" placeholder="请输入品牌商编号,公司名称"></a-input>
    </a-form-item>
    <a-form-item label="创建时间" name="">
      <a-range-picker valueFormat="YYYY-MM-DD" format="YYYY-MM-DD" @change="onChange" />
    </a-form-item>
    <a-form-item label="交易类型" name="">
      <a-select v-model:value="search.type" :style="{ width: '180px' }" placeholder="全部">
        <a-select-option value="">全部</a-select-option>
        <a-select-option :value="item.type" v-for='item in typeLits' :key='item'>{{item.name}}</a-select-option>
      </a-select>
    </a-form-item>
    <a-form-item>
      <a-button type="primary" htmlType="submit">搜索</a-button>
    </a-form-item>
  </a-form>
    <a-table bordered :data-source="dataSource" :columns="columns" :pagination="false" rowKey="id"
      :loading="listLoading" class="tableFixBox" :scroll="{ x: 'max-content'}">
      <template v-slot:pm="{ record }">
        <p>{{ record.pm == 1 ? "收入：" : "支出：" }}￥{{ record.number }}</p>
      </template>
    </a-table>
    <a-pagination :style="{ marginTop: '20px', textAlign: 'right' }" show-size-changer show-quick-jumper
      :current="search.page" :page-size="search.limit" :total="total" @change="pageCurrentChange"
      @showSizeChange="pageSizeChange" :show-total="total => `总数: ${total} `" />
</template>

<script>
  import { ref, onMounted, reactive, toRefs } from "vue";
  import { getBrandBillsList, getBrandBillsType } from "@/api/financial";
  import { $iscode } from "@/utils/app";
  import { message } from "ant-design-vue";
  let defSearch = {
    keywords: "",
    withdraw: "",
    page: 1,
    limit: 10,
    type: "",
    start_time: "",
    end_time: "",
  };
  export default {
    name: 'brandList',
    components: {},
    setup(props) {
      let search = ref({ ...defSearch });
      let total = ref(1);
      const state = reactive({
        typeLits: [],
        dataSource: [],
        listLoading: false,
        columns: [
          {
            title: "流水号",
            dataIndex: "bill_no",
          },
          {
            title: "品牌商编号",
            dataIndex: "brand_no"
          },
          {
            title: "公司名称",
            customRender: ({ record }) => {
              return record.brand_company ?record.brand_company:'-'
            }
          },
          {
            title: "交易类型",
            dataIndex: "type_text",
          },
          {
            title: "收入/支出",
            key: "pm",
            slots: { customRender: "pm" },
          },
          {
            title: "余额",
            dataIndex: "balance",
          },
          {
            title: "日期",
            dataIndex: "create_time",
          },
          {
            title: "备注",
            dataIndex: "mark",
              customRender: ({ text,record }) => {
                return record.mark ?record.mark:'-'
            }
          },
        ],
      });
      // 页面筛选项搜索
      const pageSearchChange = () => {
        search.value = { ...search.value, page: 1 };
        initData(search.value);
      };
      // 分页当前页切换
      const pageCurrentChange = (page, pageSize) => {
        search.value.page = page;
        initData(search.value);
      };
      // 分页当前页显示多少条切换
      const pageSizeChange = (current, size) => {
        search.value.page = 1;
        search.value.limit = size;
        initData(search.value);
      };

      const getType = async () => {
        let res = await getBrandBillsType().then(res => res.data);
        if ($iscode(res)) {
          state.typeLits = res.data;
        } else {
          message.error(res.msg);
        }
      }
      //代理商列表
      const initData = async (values) => {
        state.listLoading = true;
        try {
          let res = await getBrandBillsList(values).then((res) => res.data);
          state.listLoading = false;
          if ($iscode(res)) {
            state.dataSource = res.data.data;
            total.value = res.data.total;
          } else {
            message.error(res.message);
          }
        } catch (e) {
          state.listLoading = false;
        }
      };
      const onChange = (e) => {
        if (e.length > 0) {
          search.value.start_time = e[0];
          search.value.end_time = e[1];
        } else {
          search.value.start_time = "";
          search.value.end_time = "";
        }
      };
      onMounted(() => {
        initData(search.value);
        getType();
      });
      return {
        ...toRefs(state),
        initData,
        search,
        total,
        pageSearchChange,
        pageCurrentChange,
        pageSizeChange,
        onChange,
      };
    },
  };
</script>

<style lang="less"></style>